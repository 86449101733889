import { ROUTINE_PROMISE_ACTION } from './constants';
export default function promisifyRoutine(routine) {
  return function (payload, dispatch) {
    return new Promise(function (resolve, reject) {
      return dispatch({
        type: ROUTINE_PROMISE_ACTION,
        payload: payload,
        meta: {
          defer: {
            resolve: resolve,
            reject: reject
          },
          routine: routine
        }
      });
    });
  };
}