"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = promisifyRoutine;

var _constants = require("./constants");

function promisifyRoutine(routine) {
  return function (payload, dispatch) {
    return new Promise(function (resolve, reject) {
      return dispatch({
        type: _constants.ROUTINE_PROMISE_ACTION,
        payload: payload,
        meta: {
          defer: {
            resolve: resolve,
            reject: reject
          },
          routine: routine
        }
      });
    });
  };
}