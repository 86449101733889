"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tradingApplications = void 0;
var tradingApplications = [{
  name: 'CQG',
  value: 'cqg',
  disabled: false,
  useSSO: false
}, {
  name: 'Deltix',
  value: 'deltix',
  disabled: true,
  useSSO: true
}, {
  name: 'Etale',
  value: 'etale',
  disabled: false,
  useSSO: true
}, {
  name: 'Cboe Digital Trading UI',
  value: 'erisx',
  disabled: false,
  useSSO: true
}, {
  name: 'RSBIX',
  value: 'rsbix',
  disabled: false,
  useSSO: false
}, {
  name: 'capitalise.ai',
  value: 'capitalise',
  disabled: false,
  useSSO: true
}, {
  name: 'capitalise.ai_qa',
  value: 'capitaliseQa',
  disabled: false,
  useSSO: true
}];
exports.tradingApplications = tradingApplications;