import getValue from './getValue';
import isReactNative from '../isReactNative';

var onChangeValue = function onChangeValue(event, _ref) {
  var name = _ref.name,
      parse = _ref.parse,
      normalize = _ref.normalize;
  // read value from input
  var value = getValue(event, isReactNative); // parse value if we have a parser

  if (parse) {
    value = parse(value, name);
  } // normalize value


  if (normalize) {
    value = normalize(name, value);
  }

  return value;
};

export default onChangeValue;