"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCreatorForType = exports.toLowerCase = exports.toUpperCase = exports.toCamelCase = exports.isFunction = void 0;

var isFunction = function isFunction(value) {
  return typeof value === 'function';
};

exports.isFunction = isFunction;

var toCamelCase = function toCamelCase(value) {
  return String(value).toLowerCase().replace(/_+(\w)/g, function (_, p1) {
    return p1.toUpperCase();
  });
};

exports.toCamelCase = toCamelCase;

var toUpperCase = function toUpperCase(value) {
  return String(value).toUpperCase();
};

exports.toUpperCase = toUpperCase;

var toLowerCase = function toLowerCase(value) {
  return String(value).toLowerCase();
};

exports.toLowerCase = toLowerCase;

var getCreatorForType = function getCreatorForType(type, creator) {
  if (!creator) {
    return creator;
  }

  if (isFunction(creator[type])) {
    return creator[type];
  }

  if (isFunction(creator[toLowerCase(type)])) {
    return creator[toLowerCase(type)];
  }

  if (isFunction(creator[toUpperCase(type)])) {
    return creator[toUpperCase(type)];
  }

  if (isFunction(creator[toCamelCase(type)])) {
    return creator[toCamelCase(type)];
  }

  if (isFunction(creator)) {
    return creator;
  }

  return undefined;
};

exports.getCreatorForType = getCreatorForType;