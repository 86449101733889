"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _routineStages = _interopRequireDefault(require("./routineStages"));

var _createRoutineCreator = _interopRequireDefault(require("./createRoutineCreator"));

var createRoutine = (0, _createRoutineCreator["default"])(_routineStages["default"]);
var _default = createRoutine;
exports["default"] = _default;