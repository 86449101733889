"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.FULFILL = exports.FAILURE = exports.SUCCESS = exports.REQUEST = exports.TRIGGER = void 0;
var TRIGGER = 'TRIGGER';
exports.TRIGGER = TRIGGER;
var REQUEST = 'REQUEST';
exports.REQUEST = REQUEST;
var SUCCESS = 'SUCCESS';
exports.SUCCESS = SUCCESS;
var FAILURE = 'FAILURE';
exports.FAILURE = FAILURE;
var FULFILL = 'FULFILL';
exports.FULFILL = FULFILL;
var _default = [TRIGGER, REQUEST, SUCCESS, FAILURE, FULFILL];
exports["default"] = _default;