"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createRoutineCreator;

var _reduxActions = require("redux-actions");

var _utils = require("./utils");

function createRoutineCreator(stages) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
  var stagesArray = stages;

  if (!Array.isArray(stagesArray)) {
    stagesArray = [].concat(stages);
  }

  if (!stages || stagesArray.length === 0) {
    throw new Error('Routine `stages` must not be empty');
  }

  var createRoutine = function createRoutine(typePrefix, payloadCreator, metaCreator) {
    if (typeof typePrefix !== 'string') {
      throw new Error('Routine `typePrefix` must be a string');
    }

    var createActionCreator = function createActionCreator(type) {
      return (0, _reduxActions.createAction)("".concat(typePrefix).concat(separator).concat(type), (0, _utils.getCreatorForType)(type, payloadCreator), (0, _utils.getCreatorForType)(type, metaCreator));
    };

    var routine = stagesArray.reduce(function (result, stage) {
      var actionCreator = createActionCreator(stage);
      result[(0, _utils.toCamelCase)(stage)] = actionCreator;
      result[(0, _utils.toUpperCase)(stage)] = actionCreator.toString();
      return result;
    }, createActionCreator(stagesArray[0]));
    routine._STAGES = stagesArray;
    routine._PREFIX = typePrefix;
    return routine;
  };

  createRoutine.STAGES = stagesArray;
  return createRoutine;
}

;