import { createAction } from 'redux-actions';
import { getCreatorForType, toCamelCase, toUpperCase } from './utils';
export default function createRoutineCreator(stages, separator) {
  if (separator === void 0) {
    separator = '/';
  }

  var stagesArray = stages;

  if (!Array.isArray(stagesArray)) {
    stagesArray = [].concat(stages);
  }

  if (!stages || stagesArray.length === 0) {
    throw new Error('Routine `stages` must not be empty');
  }

  var createRoutine = function createRoutine(typePrefix, payloadCreator, metaCreator) {
    if (typeof typePrefix !== 'string') {
      throw new Error('Routine `typePrefix` must be a string');
    }

    var createActionCreator = function createActionCreator(type) {
      return createAction("" + typePrefix + separator + type, getCreatorForType(type, payloadCreator), getCreatorForType(type, metaCreator));
    };

    var routine = stagesArray.reduce(function (result, stage) {
      var actionCreator = createActionCreator(stage);
      result[toCamelCase(stage)] = actionCreator;
      result[toUpperCase(stage)] = actionCreator.toString();
      return result;
    }, createActionCreator(stagesArray[0]));
    routine._STAGES = stagesArray;
    routine._PREFIX = typePrefix;
    return routine;
  };

  createRoutine.STAGES = stagesArray;
  return createRoutine;
}
;