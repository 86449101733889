export var isFunction = function isFunction(value) {
  return typeof value === 'function';
};
export var toCamelCase = function toCamelCase(value) {
  return String(value).toLowerCase().replace(/_+(\w)/g, function (_, p1) {
    return p1.toUpperCase();
  });
};
export var toUpperCase = function toUpperCase(value) {
  return String(value).toUpperCase();
};
export var toLowerCase = function toLowerCase(value) {
  return String(value).toLowerCase();
};
export var getCreatorForType = function getCreatorForType(type, creator) {
  if (!creator) {
    return creator;
  }

  if (isFunction(creator[type])) {
    return creator[type];
  }

  if (isFunction(creator[toLowerCase(type)])) {
    return creator[toLowerCase(type)];
  }

  if (isFunction(creator[toUpperCase(type)])) {
    return creator[toUpperCase(type)];
  }

  if (isFunction(creator[toCamelCase(type)])) {
    return creator[toCamelCase(type)];
  }

  if (isFunction(creator)) {
    return creator;
  }

  return undefined;
};