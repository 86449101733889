"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pino = require("pino");
var serialize_error_1 = require("serialize-error");
var expressPino;
var isServer = typeof module !== 'undefined' && module.exports;
if (isServer) {
    try {
        expressPino = require('express-pino-logger');
    }
    catch (_) { }
}
// TODO have this configurable
var numDirsToKeep = 4;
exports.flattenPath = function (fullpath) {
    var dirs = fullpath.split('/');
    var numDirs = dirs.length;
    return dirs.map(function (folder, index) {
        if (numDirs - index <= numDirsToKeep) {
            return folder;
        }
        else {
            if (folder.length > 0) {
                return folder[0];
            }
            else {
                return "";
            }
        }
    }).join('/');
};
var getServiceName = function (name) {
    if (name) {
        return name;
    }
    else {
        if (isServer && module.parent && module.parent.filename) {
            // if name is not specified it will just use the filename
            // of whoever required this module
            return exports.flattenPath(module.parent.filename);
        }
        else {
            return 'root-logger';
        }
    }
};
var attachErisLoggerFunctions = function (p, log_name) {
    var toReturn = p;
    toReturn.log_name = log_name;
    toReturn.withCtxId = function (ctxId, ctxBlock) {
        var child_logger = toReturn.child({ ctxId: ctxId });
        child_logger.ctxId = ctxId;
        child_logger = attachErisLoggerFunctions(child_logger, log_name);
        ctxBlock(child_logger);
    };
    toReturn.withMap = function (map, mapBlock) {
        var child_logger = p.child(map);
        child_logger.logMap = map;
        child_logger = attachErisLoggerFunctions(child_logger, log_name);
        mapBlock(child_logger);
    };
    return toReturn;
};
var logger_mix_in = function () {
    return isServer ? { log_app_name: process.env.LOG_APP_NAME || 'eris_js_logger' } : {};
};
exports.logger = function (name) {
    var log_name = getServiceName(name);
    var p = pino({
        name: log_name,
        mixin: logger_mix_in
    });
    return attachErisLoggerFunctions(p, log_name);
};
;
var stack_to_array = function (stack) {
    if (stack) {
        return stack.split('\n').map(function (x) { return x.trim(); });
    }
    else {
        return [];
    }
};
exports.error_obj = function (error, msg, includeStack) {
    if (includeStack === void 0) { includeStack = true; }
    var serialized = serialize_error_1.serializeError(error);
    var isPrim = (typeof serialized !== 'object' && typeof serialized !== 'function') || serialized === null;
    if (isPrim) {
        var toReturn = {
            'exception': {
                'detail': {
                    'message': serialized,
                },
                'message': msg
            }
        };
        if (includeStack) {
            //you don't want this line in the stack trace because that's just kind of confusing...
            var currentStack = stack_to_array(new Error().stack);
            currentStack.splice(1, 1);
            toReturn.exception.detail.stack = currentStack;
        }
        return toReturn;
    }
    if (!includeStack) {
        serialized.stack = undefined;
    }
    else {
        if (serialized.stack) {
            serialized.stack = stack_to_array(serialized.stack);
        }
        else {
            var currentStack = stack_to_array(new Error().stack);
            currentStack.splice(1, 1);
            serialized.stack = currentStack;
        }
    }
    return {
        'exception': {
            'detail': serialized,
            'message': msg
        }
    };
};
/* This will attach a pino logger to the express app
   and it will also expose the logger to the request object
   Ex:
    app.get('/', function (req, res) {
      req.log.info('something')
      res.send('hello world')
   });
*/
exports.attach_logger_to_express = function (expressApp, customName) {
    if (expressPino) {
        var expressLogger = null;
        if (customName && customName.trim() != '') {
            // reuse a normal pino logger if a custom name is passed in.
            expressLogger = expressPino({
                logger: exports.logger(customName)
            });
        }
        else {
            expressLogger = expressPino({
                logger: pino({
                    // pass in our mix_in object
                    mixin: logger_mix_in
                })
            });
        }
        expressApp.use(expressLogger);
    }
};
