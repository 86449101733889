import _toConsumableArray from 'babel-runtime/helpers/toConsumableArray';
import _Object$entries from 'babel-runtime/core-js/object/entries';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _slicedToArray from 'babel-runtime/helpers/slicedToArray';
import { header as ccHeader } from 'change-case';
import { snake, camel, header } from './transform';

export var snakeParams = function snakeParams(config) {
  if (config.params) {
    config.params = snake(config.params);
  }
  return config;
};
export var snakeRequest = function snakeRequest(data, headers) {
  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = _getIterator(_Object$entries(headers)), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var _ref = _step.value;

      var _ref2 = _slicedToArray(_ref, 2);

      var key = _ref2[0];
      var value = _ref2[1];

      header(value, true);
      if (!['common', 'delete', 'get', 'head', 'post', 'put', 'patch'].includes(key)) {
        delete headers[key];
        headers[ccHeader(key)] = value;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return snake(data);
};
export var camelResponse = function camelResponse(data, headers) {
  camel(headers, true);
  return camel(data);
};

var applyConverters = function applyConverters(axios) {
  axios.defaults.transformRequest = [snakeRequest].concat(_toConsumableArray(axios.defaults.transformRequest));
  axios.defaults.transformResponse = [].concat(_toConsumableArray(axios.defaults.transformResponse), [camelResponse]);
  axios.interceptors.request.use(snakeParams);
  return axios;
};

export default applyConverters;