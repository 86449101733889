import { ROUTINE_PROMISE_ACTION } from './constants';
export default function bindRoutineToReduxForm(routine, noSuccessPayload) {
  if (noSuccessPayload === void 0) {
    noSuccessPayload = false;
  }

  return function (values, dispatch, props) {
    return new Promise(function (resolve, reject) {
      return dispatch({
        type: ROUTINE_PROMISE_ACTION,
        payload: {
          values: values,
          props: props
        },
        meta: {
          defer: {
            resolve: resolve,
            reject: reject
          },
          routine: routine,
          noSuccessPayload: noSuccessPayload
        }
      });
    });
  };
}