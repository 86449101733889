import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isArray } from 'lodash';
import {
  createLoadingSelector,
  sortedArrayToAttrValue,
  filteredArrayToAttrValue,
  getItemsPaged,
  showModal,
  hideModal,
  DEFAULT_FILTER_OP,
} from 'erisxkit/client';
import riskOverviewMetadata from '../../metadata/riskOverviewMetadata';
import {
  fetchRisk,
  getRiskSummary,
  RISK_ACCOUNT_SUMMARY,
} from '../../reducers/riskReducer';
import EnvPromise from '../../config/env';
import XTable7 from '../../common/table/XTable7';

const mapStateToProps = (state = {}) => ({
  riskLoading: createLoadingSelector([RISK_ACCOUNT_SUMMARY])(state),
  count: get(state, 'risk.risk.count', 0),
  risk: getRiskSummary(state),
});

const mapDispatchToProps = {
  fetchRisk,
  showModal,
  hideModal,
};

const getFilterOp = (columns, id) => {
  const column = columns.find((c) => c.id === id);
  return column.filterOp || DEFAULT_FILTER_OP;
};

const SECONDS_TO_NANO = '000000000';

class Overview extends Component {
  state = {
    tcsIndependentClearingEnabled: false,
    envLoaded: false,
  };

  fetchData = ({ pageSize, pageIndex, filters, sortBy, columns }) => {
    let filter = [];
    let sort = [];
    if (filters.length !== 0) {
      filter = filteredArrayToAttrValue(
        filters.map((each) => ({
          ...each,
          op: getFilterOp(columns, each.id),
        })),
      );
    }
    // TODO: FIX GTE/LTE logic
    // right now if value is array we'll treat it as gte/lte values.
    const filterWithMaxMin = filter.reduce((acc, curr) => {
      if (isArray(curr.value)) {
        return acc.concat([
          ...(curr.value[0]
            ? [
                {
                  ...curr,
                  op: 'gte',
                  value: `${curr.value[0]}${SECONDS_TO_NANO}`,
                },
              ]
            : []),
          ...(curr.value[1]
            ? [
                {
                  ...curr,
                  op: 'lte',
                  value: `${curr.value[1]}${SECONDS_TO_NANO}`,
                },
              ]
            : []),
        ]);
      }
      return acc.concat([curr]);
    }, []);

    if (sortBy.length !== 0) {
      sort = sortedArrayToAttrValue(sortBy);
    }
    this.props.fetchRisk({
      limit: pageSize,
      page: pageIndex,
      offset: pageSize * pageIndex,
      filter: filterWithMaxMin,
      sort,
    });
  };

  componentDidMount = () => {
    EnvPromise.then(({ tcsIndependentClearingEnabled }) =>
      this.setState({
        tcsIndependentClearingEnabled: tcsIndependentClearingEnabled,
        envLoaded: true,
      }),
    );
  };

  render = () => {
    const { risk, riskLoading, count } = this.props;
    return this.state.envLoaded ? (
      <XTable7
        metadata={riskOverviewMetadata(
          this.state.tcsIndependentClearingEnabled,
        )}
        data={riskLoading ? [] : risk || []}
        fetchData={this.fetchData}
        loading={riskLoading}
        selectMember={this.selectMember}
        clearSelectedMember={this.clearSelectedMember}
        count={count}
        pageSize={50}
        showFooterQuantity={false}
        height={'60vh'}
      />
    ) : (
      <div></div>
    );
  };
}

Overview.propTypes = {
  count: PropTypes.number,
  fetchRisk: PropTypes.func.isRequired,
};

Overview.defaultProps = {
  count: 0,
  members: [],
  membersLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
