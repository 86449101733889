'use strict';
const bs58 = require('bs58');

const crypto = require('crypto');
const BigInteger = require('bigi');
const ecurve = require('ecurve');
const ecdsa = require('ecdsa');
const pbkdf2 = require('pbkdf2-sha256');
const { logger, error_obj } = require('eris_js_logger');
const log = logger('ecdsa');

function sha256(str) {
  return crypto.createHash('sha256').update(str).digest();
}

function privateKeyFromPassword(username, password) {
  return pbkdf2(password, username, 100000, 32);
}

function publicKeyFromPrivateKey(privateKey) {
  let ecparams = ecurve.getCurveByName('secp256k1');
  let curvePt = ecparams.G.multiply(BigInteger.fromBuffer(privateKey));
  let x = curvePt.affineX.toBuffer(32);
  let y = curvePt.affineY.toBuffer(32);
  return Buffer.concat([new Buffer([0x04]), x, y]);
}

function publicKeyFromPassword(username, password) {
  return publicKeyFromPrivateKey(privateKeyFromPassword(username, password));
}

function signMessage(message, privateKey) {
  let shaMsg = sha256(message);
  let signature = ecdsa.sign(shaMsg, BigInteger.fromBuffer(privateKey));
  return signature.toDER();
}

function publicKeyToPoint(publicKey) {
  let x = BigInteger.fromBuffer(publicKey.slice(1,33));
  let y = BigInteger.fromBuffer(publicKey.slice(33,65));
  let ecparams = ecurve.getCurveByName('secp256k1');
  return ecurve.Point.fromAffine(ecparams, x, y);
}

function verifySignature(message, signature, publicKey) {
  let shaMsg = sha256(message);
  let sig = ecdsa.ECSignature.fromDER(signature);
  return ecdsa.verify(shaMsg, sig, publicKeyToPoint(publicKey));
}

function verifySigBs58(message, signature, pub_key) {
  try {
    const valid = verifySignature(message, bs58.decode(signature), bs58.decode(pub_key));
    if (valid) {
      return true;
    }
    log.info({
      'event' : 'invalid_signature',
      'signature_type' : 'bs58',
      'message' : message
    });
  } catch (e) {
    log.error(error_obj(e, 'Error verifying bs58 signature'));
  }
  return false;
}

function pubKeyBs58(username, password) {
  return bs58.encode(publicKeyFromPassword(username, password));
}

function signMsgBs58(message, privateKey) {
  return bs58.encode(signMessage(message, privateKey));
}

module.exports = exports = {
  sha256,
  privateKeyFromPassword,
  publicKeyFromPrivateKey,
  publicKeyFromPassword,
  signMessage,
  verifySignature,
  verifySigBs58,
  pubKeyBs58,
  signMsgBs58
}
